<style src="../css/head.css"></style>

<style src="../css/detail.css"></style>
<template>
  <div>
  <Head-section></Head-section>
  <div class="container">
    <div class="text-center  mt-5">
      <a href="#" @click="toHome()">
        <img src="../assets/logo.png" class="img-fluid" alt="Responsive image">
      </a>
    </div>
    <h1 class="text-center text-muted">{{$t("message.Retrieve_Password")}}</h1>

    <!-- <form @submit="forgetPswByEmail(usrname,email)">
                            <div class="form-group">
                                 <label for="exampleInputaUsrName">{{$t("message.email")}}</label>
                                    <input type="email" class="form-control" id="exampleInputaUsrName" placeholder="Email" v-model="email">
                            </div>
                    
                            <small class="form-text text-danger" v-if="lan=='cn'">{{ foget_msg}}</small>
                                    <small class="form-text text-danger" v-if="lan=='en'">{{ foget_msg_en}}</small>
                            <button type="submit" class="btn btn-outline-dark btn-lg btn-block">{{$t("message.Next_Step")}}</button>
    </form>-->
    <b-card border-variant="light">
      <b-form>
        <b-form-group :label="$t('message.email')" label-for="input-2">
          <b-form-input
            id="input-2"
            v-model="email"
            type="email"
            required
            :placeholder="$t('message.Please_enter_the_email')"
          ></b-form-input>
        </b-form-group>
        <div class="text-center">
          <b-button
            type="button"
            variant="outline-dark"
            @click="forgetPswByEmail(usrname,email)"
          >{{$t("message.Next_Step")}}</b-button>
        </div>
        <small class="form-text text-danger" v-if="lan=='cn'" :class="{'d-none':rsl}">{{ foget_msg}}</small>
        <small
          class="form-text text-danger"
          v-if="lan=='en'"
          :class="{'d-none':rsl}"
        >{{ foget_msg_en}}</small>
        <small
          class="form-text text-success"
          v-if="lan=='cn'"
          :class="{'d-none':rsl_req}"
        >{{ foget_msg}}</small>
        <small
          class="form-text text-success"
          v-if="lan=='en'"
          :class="{'d-none':rsl_req}"
        >{{ foget_msg_en}}</small>
      </b-form>
    </b-card>
  </div>
   <Foot-section></Foot-section>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import HeadSection from "./HeadSection.vue";
import FootSection from "./FootSection.vue";
export default {
  name: "ForgetPswEmail",
  components: {
    HeadSection,
    FootSection
  },
  data() {
    return {
      email: "",
      usrname: "",
      foget_msg: "",
      foget_msg_en: "",
      rsl: true,
      rsl_req: true
    };
  },
  computed: {
    ...mapGetters(["lan"])
  },
  created() {
    this.toForgePsw();
    this.usrname = this.$route.params.usrnm;
  },
  methods: {
    ...mapActions({
      forgetPswByEmail(dispatch, username, email) {
        dispatch("forgetPswByEmail", { username, email }).then(data => {
          this.rsl = data.result;
          this.foget_msg_en = data.message_en;
          this.foget_msg = data.message;
          this.rsl_req = true;
          if (data.result) {
            this.rsl_req = false;
          }
        });
      }
    }),
    toHome() {
      this.$router.push({
        name: "enhome"
      });
    },
    toForgePsw() {
      // console.log("=====this.$route.params.usrnm========" + this.$route.params.usrnm)
      if (
        this.$route.params.usrnm == "" ||
        this.$route.params.usrnm == null ||
        this.$route.params.usrnm == "undefined"
      ) {
        this.$router.push({
          name: "enForgetPsw"
        });
      }
    }
  }
};
</script>
